export {COLORS} from './COLORS';
export {
  HODLS_INSTRUMENTS_SECTORS_COLOR_TOKENS,
  type TColorTokens,
  type TColorTokensNames,
  type TLoyaltyColorTokens,
  type TLoyaltyColorTokensNames,
  type TSectorColorTokensNames,
  type TSectorsColorTokens,
} from './TOKENS';

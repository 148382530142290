export {spacing, spacingShorthand} from './spacing';
export type {SpacingProps, SpacingShorthandProps} from './spacing';

export {backgroundColor, backgroundColorShorthand} from './background';

export type {
  BackgroundColorProps,
  BackgroundColorShorthandProps,
} from './background';

export {color} from './color';

export type {ColorProps} from './color';

import {WebAppEnv} from './WebAppEnv';

/** @platform web */
export const getWebAppEnvFromHost = () => {
  const host = window.location.host;

  if (host.includes('ledgerlive.youhodler')) {
    return WebAppEnv['ledger-app'];
  }

  if (host.includes('ramp.youhodler')) {
    return WebAppEnv['ramp-app'];
  }

  return WebAppEnv['web-app'];
};
